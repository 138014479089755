import React from "react";
import { Grid } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle = (evt) => {
    const { mobileOpen } = this.state;
    evt.preventDefault();
    evt.stopPropagation();
    evt.nativeEvent.preventDefault();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    this.setState({
      mobileOpen: !mobileOpen,
    });
  };

  render() {
    const { mobileOpen } = this.state;
    return (
      <header id="header" className={mobileOpen ? "open" : ""}>
        <Grid className="header-content clearfix" fluid>
          <NavLink className="logo" to="/">
            <img
              src="images/logo.png"
              className="logoImage"
              alt="Milena Decker"
            />
          </NavLink>
          <nav className={`navigation ${mobileOpen ? "open" : ""}`}>
            <ul className="primary-nav">
              <li>
                <NavLink to="/" activeClassName="active" exact>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/perfil" activeClassName="active">
                  Perfil
                </NavLink>
              </li>
              <li>
                <NavLink to="/portfolio" activeClassName="active">
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink to="/clipping" activeClassName="active">
                  Clipping
                </NavLink>
              </li>
              <li>
                <NavLink to="/contato" activeClassName="active">
                  Contato
                </NavLink>
              </li>
              <li>
                <a
                  className="lnkFacebook"
                  href="https://www.facebook.com/milenadeckerarquitetura"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="linkTxt">Facebook</span>
                  <span className="linkImg">
                    <img
                      src="images/facebook-icon.png"
                      width="30"
                      height="30"
                      alt="Facebook"
                    />
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="lnkInstagram"
                  href="https://www.instagram.com/milenadeckerarquitetura/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="linkTxt">Instagram</span>
                  <span className="linkImg">
                    <img
                      src="images/instagram-icon.png"
                      width="30"
                      height="30"
                      alt="Instagram"
                    />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <a
            ref={(ref) => (this.navToggle = ref)}
            className={`nav-toggle ${mobileOpen ? "close-nav" : ""}`}
            onClick={this.handleToggle}
          >
            Menu
            <span></span>
          </a>
        </Grid>
      </header>
    );
  }
}

export default Header;
