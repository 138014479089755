import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

class PhotoThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { photoIndex, clickHandler } = this.props;
    if (typeof clickHandler === 'function') {
      clickHandler(photoIndex);
    }
  }

  render() {
    const { photoIndex, photo } = this.props;
    const { thumbnail, alt } = photo;

    return (
      <Col className="photoThumbnail" data-photo-index={photoIndex}> 
        <a onClick={this.handleClick}>
          <img
            src={`images/thumbnails/${thumbnail}`}
            alt={alt}
          />
        </a>
      </Col>
    );
  }
}

PhotoThumbnail.propTypes = {
  photoIndex: PropTypes.number,
  photo: PropTypes.object,
  clickHandler: PropTypes.func,
};

export default PhotoThumbnail;