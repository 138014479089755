import 'whatwg-fetch';
import { Col, Grid, Row } from 'react-bootstrap';
import React from 'react';

class Footer extends React.Component {
  constructor() {
    super();
    this.state = { 
      startYear: '2018',
      currentYear: '2018',
    };
  }

  componentDidMount() {
    this.getCurrentYear();
  }

  getCurrentYear() {
   fetch('localhost/datetime.php')
    .then(response => {
      const contentType = response.headers.get("content-type");
      if(contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then(json => {
          this.setState({ currentYear: json.year });
        });
      }
    })   
  }

  render() {
    const { startYear, currentYear } = this.state;
    const yearString = parseInt(currentYear,10) > parseInt(startYear,10) ? `${startYear} - ${currentYear}` : startYear;
    return (
      <footer id="footer" className="pageFooter">
        <Grid fluid>
          <Row>
            <Col
              xs={12}
              className="footerText text-center"
            >
              <span className="footerSpanLeft">
                &copy; Milena Decker Arquitetura &ndash; {yearString}
              </span>
              <span> | </span>
              <span className="footerSpanRight">
                <span className="hidden-xs">Desenvolvido por&nbsp;</span>
                <a href="https://www.instagram.com/vullcandesign/" target="_blank" rel="noopener noreferrer">Vullcan Design</a>
              </span>
            </Col>
          </Row>
        </Grid>
      </footer>
    );
  }
}

export default Footer;