import 'whatwg-fetch';
import FormButton from '../Common/FormButton';
import FormInput from '../Common/FormInput';
import React from 'react';
import { Alert, Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name: '',
      phone: '',
      mail: '',
      message: '',
      error: null,
      sent: false,
    }
    this.formatPhone = this.formatPhone.bind(this);
    this.clearError = this.clearError.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMail = this.onChangeMail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
  }

  onChangeName(name) { this.setState({ name }); }
  onChangeMail(mail) { this.setState({ mail }); }
  onChangePhone(phone) { this.setState({ phone }); }
  onChangeMessage(message) { this.setState({ message }); }

  formatPhone(value = '') {
    const clear = value.toString().replace(/\D/g, '').slice(0,11);
    const regex = clear.length > 8 ? (
      clear.length > 9 ? (
        clear.length > 10 ? /^(\d{1,2})?(\d{1,5})?(\d{1,4})?$/ : /^(\d{1,2})?(\d{1,4})?(\d{1,4})?$/
      ) : /^(\d{1,5})?(\d{1,4})?$/
    ) : /^(\d{1,4})?(\d{1,4})?$/;
    const ret = regex.exec(clear);
    const format = ret[2] ? (ret[3] ? '($1) $2-$3' : '$1-$2' ) : '$1';
    const formatted = clear.replace(regex, format);
    return formatted;
  }

  validateEmail(value = '') {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? 'success' : 'error';
  }

  validateMessage(value = '') {
    return /^.*?\S+.*?$/.test(value) ? 'success' : 'error';
  }

  validateName(value = '') {
    return /^.*?\S+.*?$/.test(value) ? 'success' : 'error';
  }

  validatePhone(value = '') {
    const clear = value.toString().replace(/\D/g, '').slice(0,11);
    const regex = clear.length > 8 ? (
      clear.length > 9 ? (
        clear.length > 10 ? /^\((\d{2})\)\s(\d{5})-(\d{4})?$/ : /^\((\d{2})\)\s(\d{4})-(\d{4})?$/
      ) : /^9(\d{4})-(\d{4})?$/
    ) : /^(\d{4})-(\d{4})?$/;
    return regex.test(value) ? 'success' : 'error';
  }

  clearError() {
    this.setState({ error: null });
  }

  sendMessage() {
    this.setState({ error: null }, () => {
      const isFormValid = this.name.isValid() && this.phone.isValid() && this.mail.isValid() &&  this.message.isValid();
      if (isFormValid) {
        const sendData = {
          name: this.name.getValue(),
          phone: this.phone.getValue(),
          mail: this.mail.getValue(),
          message: this.message.getValue(),
        };
        const request = {
          headers: {
            'Accept': 'application/json',
          },
          method: "POST",
          body: JSON.stringify(sendData),
        };
        fetch('sendmail.php', request)
          .then(response => {
            const contentType = response.headers.get("content-type");
            if(contentType && contentType.indexOf("application/json") !== -1) {
              response.json().then((json) => {
                const sentMail = json.sentMail;
                const message = json.message;
                if(sentMail) {
                  this.setState({ sent: true });
                } else {
                  this.setState({ error: message || 'Erro desconhecido ao enviar mensagem.' });
                }
              }, () => {
                this.setState({ error: 'Impossível interpretar dados de retorno.' });  
              });
            } else {
              this.setState({ error: 'Retorno incorreto do servidor de e-mail.' });
            }
          }, () => {
            this.setState({ error: 'Erro desconhecido ao enviar mensagem.' });
          });
      }
    })
    
  }

  renderMessage() {
    return (
      <Row className="formulario-contato">
        <Col
          xs={12}
          className="text-center mensagemEnviada"
        >
          Mensagem enviada com sucesso!
        </Col>
      </Row>
    );
  }

  renderForm() {
    const { error } = this.state;
    return (
      <Row className="formulario-contato">
        { error && (
          <Col xs={12}>
            <Alert variant="danger">
              <h4>Ocorreu um erro!</h4>
              <p>{error}</p>
              <br/>
              <p>
                <Button variant="danger" onClick={this.sendMessage}>Tentar novamente</Button>
                <span> ou </span>
                <Button onClick={this.clearError}>Cancelar</Button>
              </p>
            </Alert>
          </Col>
        )}
        <form>
          <Col className="form-column" xs={12} sm={6}>
            <FormInput
              ref={ref => this.name = ref}
              controlId="name"
              type="text"
              value={this.state.name}
              placeholder="Nome"
              validator={this.validateName}
              onChange={this.onChangeName}
              required
            />
            <FormInput
              ref={ref => this.phone = ref}
              controlId="phone"
              type="tel"
              pattern="\d*"
              value={this.state.phone}
              placeholder="Telefone"
              formatter={this.formatPhone}
              validator={this.validatePhone}
              onChange={this.onChangePhone}
              onKeyDown={this.onPhoneKeyDown}
              required
            />
            <FormInput
              ref={ref => this.mail = ref}
              controlId="mail"
              type="email"
              value={this.state.mail}
              placeholder="E-mail"
              validator={this.validateEmail}
              onChange={this.onChangeMail}
              required
            />
          </Col>
          <Col className="form-column" xs={12} sm={6}>
            <FormInput
              ref={ref => this.message = ref}
              controlId="message"
              type="textarea"
              value={this.state.message}
              placeholder="Mensagem"
              validator={this.validateMessage}
              onChange={this.onChangeMessage}
              required
            />
            <FormButton
              ref={ref => this.sendBtn = ref}
              controlId="sendBtn"
              buttonText="Enviar"
              onClick={this.sendMessage}
            />
          </Col>
        </form>
      </Row>
    );
  }

  render() {
    const { sent } = this.state;
    return (
      <div
        className="conteudo-contato"
      >
        <Grid fluid>
          <Row className="detalhes-contato">
            <Col xs={12} sm={4}>
              <h4>
                <Glyphicon glyph="map-marker" />
                &nbsp;
                <span>Porto Alegre - Rio Grande do Sul</span>
              </h4>
            </Col>
            <Col xs={12} sm={4} className="text-center">
              <h4>
                <Glyphicon glyph="earphone" />
                &nbsp;
                <span className="visible-xs"><a href="tel:+5551998240431">(51) 9.9824.0431</a></span>
                <span className="hidden-xs">(51) 9.9824.0431</span>
              </h4>
            </Col>
            <Col xs={12} sm={4} className="text-right">
              <h4>
                <Glyphicon glyph="envelope" />
                &nbsp;
                <span>contato@milenadecker.com.br</span>
              </h4>
            </Col>
          </Row>
          { sent ? this.renderMessage() : this.renderForm() }
        </Grid>
      </div>
    );
  }
}

export default ContactForm;