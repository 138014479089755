import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';

class FormInput extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      error: null,
      value: this.props.value || '',
    };

    this.getValue = this.getValue.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getValue() {
    return this.state.value;
  }

  isValid() {
    let { error, value } = this.state;
    const { required, validator } = this.props;
    if (typeof validator === 'function') {
      error = validator(value);
      this.setState({
        error
      }, () => {
        return required ? (value && !(error === 'error')) : !(error === 'error');
      });
    }
    return required ? (value && !(error === 'error')) : !(error === 'error');
  }

  handleBlur = (evt) => {
    const { validator } = this.props;
    const value = evt.target.value;
    let error = 'success';
    if (!value) {
      error = 'error';
    }
    if (typeof validator === 'function') {
      error = validator(value);
    }
    this.setState({ error });
  }

  handleChange(evt) {
    const { formatter, onChange } = this.props;
    let value = evt.target.value;
    if (typeof formatter === 'function') {
      value = formatter(value);
    }
    this.setState({
      value,
      error: null,
    }, (value) => {
      if (typeof onChange === 'function') {
        onChange(value);
      }
    });
  }

  handleKeyDown(evt) {
    const { pattern } = this.props;
    if (pattern) {
      const key = String.fromCharCode(evt.which);
      const regExp = new RegExp(pattern);
      if (!regExp.test(key)) {
        evt.preventDefault();
      }
    }
  }

  render() {
    const { error, value } = this.state;
    const { controlId, type, pattern, placeholder, required } = this.props;
    const inputTypes = {
      'text': { componentClass: 'input', type: 'text', pattern: pattern || '.*' },
      'tel': { componentClass: 'input', type: 'tel', pattern: pattern || '.*' },
      'email': { componentClass: 'input', type: 'email', pattern: pattern || '.*' },
      'textarea': { componentClass: 'textarea', type: 'text', pattern: pattern || '.*' },
    }
    const typeObj = inputTypes && inputTypes[type] ? inputTypes[type] : inputTypes['text'];

    return (
      <FormGroup
        controlId={controlId}
        validationState={error}
      >
        <FormControl
          componentClass={typeObj.componentClass}
          type={typeObj.type}
          pattern={typeObj.pattern}
          value={value}
          placeholder={placeholder}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          required={required}
        />
        <FormControl.Feedback />
      </FormGroup>
    );
  }
}

FormInput.propTypes = {
  controlId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  formatter: PropTypes.func,
  validator: PropTypes.func,
  required: PropTypes.bool,
};

export default FormInput;