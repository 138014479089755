import Footer from './Footer';
import Header from './Header';
import PropTypes from 'prop-types';
import React from 'react';
import VullcanModal from './VullcanModal';
  
class PageWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVullcanModal: false,
      konamiCodePosition: 0,
    }
    this.childRefs = [];
    this.allowedKeys = {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      65: 'a',
      66: 'b'
    };
    this.konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.closeVullcanModal = this.closeVullcanModal.bind(this)
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(evt) {
    let { konamiCodePosition } = this.state;
    var key = this.allowedKeys[evt.which];
    var requiredKey = this.konamiCode[konamiCodePosition];
    if (key === requiredKey) {
      konamiCodePosition++;
      this.setState({
        konamiCodePosition,
      },() => {
        if (konamiCodePosition === this.konamiCode.length) {
          this.setState({
            showVullcanModal: true,
            konamiCodePosition: 0,
          });
        }
      });
      return true;
    }
    this.setState({
      konamiCodePosition: 0,
    });
  }

  closeVullcanModal() {
    this.setState({
      showVullcanModal: false,
    });
  }

  handleRef(child, value, index) {
    if (typeof(child.ref) === 'function') {
      child.ref(value);
    }
    this.childRefs[index] = value;
  };

  render() {
    const { pageName, header, footer } = this.props;
    const children = React.Children.map(this.props.children, (child, index) => {
      if (!child) return null;

      return React.cloneElement(child, {
        ref: value => this.handleRef(child, value, index),
      });
    });

    return (
      <div
        className={`PageWrapper page${pageName}`}
      >
        { header ? (
          <section
            key={`pageHeader`}
            className={`section section-header`}
          >
            <Header />
          </section>
        ) : null }
        <section
          key={`pageContent`}
          className={`section section-content`}
        >
          {children}
        </section>
        { footer ? (
          <section
            key={`pageFooter`}
            className={`section section-footer`}
          >
            <Footer />
          </section>
        ) : null }
        <VullcanModal
          show={this.state.showVullcanModal}
          onClose={this.closeVullcanModal}
        />
      </div>
    );
  }
}

PageWrapper.propTypes = {
  pageName: PropTypes.string,
  header: PropTypes.bool,
  footer: PropTypes.bool,
};

PageWrapper.defaultProps = {
  pageName: null,
  header: true,
  footer: false,
};

export default PageWrapper;