import PageWrapper from '../components/Common/PageWrapper';
import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';

class Perfil extends React.Component {
  render() {
    return (
      <PageWrapper
        pageName="Perfil"
        header={true}
        footer={false}
      >
        <Grid fluid>
          <Row>
            <Col className="profilePhoto">
              <img 
                src="images/foto-perfil.jpg"
                className="img-responsive"
                alt="Milena Meira Decker"
              />
            </Col>
            <Col className="profileText">
              <p>
                Milena Meira Decker, é formada pela faculdade de Arquitetura e Urbanismo da UniRitter em 2006 e
                pós-graduada no curso de Especialização em Arquitetura Comercial da Unisinos em 2008.<br/>
                Atua na área de projetos de arquitetura de interiores desde 2001 em Porto Alegre.
              </p>
              <p>
                Com foco em projetos residenciais e comerciais, conta com equipe de trabalho para a completa
                execução de seus projetos.
              </p>
              <p>
                Comprometimento em desenvolver um trabalho diferenciado, com alta qualidade e satisfação do
                cliente é sua meta.
              </p>
            </Col>
            <Col className="profileText">
              <p>
                <span className="quotes">&ldquo;</span>Passamos a maior parte do tempo em casa ou em nosso local de trabalho. A qualidade desses
                espaços tem influência direta no nosso bem-estar e na qualidade das atividades que ali realizamos.
              </p>
              <p>
                Ter a oportunidade de construir ou reformar um imóvel para que ele corresponda exatamente aos nossos
                desejos é uma grande realização pessoal e merece um planejamento cuidadoso, que leve em consideração
                todos os aspectos técnicos, estéticos, funcionais e financeiros envolvidos em uma obra, além da
                personalidade e estilo de vida dos proprietários.
              </p>
              <p>
                Com este propósito, tenho ajudado as pessoas a concretizarem seus planos por meio de meus
                projetos.<span className="quotes">&rdquo;</span> &ndash; Milena
              </p>
            </Col>
          </Row>
        </Grid>
      </PageWrapper>
    );
  }
}

export default Perfil;
