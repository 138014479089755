import React from "react";

class Clip extends React.Component {
  renderClip(clip) {
    const { alt, img, text } = clip;
    return (
      <div className="col-xs-12 col-sm-6 col-clipping">
        <img
          src={`images/clipping/${img}`}
          className="img-responsive center-block"
          alt={alt}
        />
        <p>{text}</p>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { url } = data;

    return url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {this.renderClip(data)}
      </a>
    ) : (
      this.renderClip(data)
    );
  }
}

export default Clip;
