import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Modal, Row } from 'react-bootstrap';

class VullcanModal extends React.Component {

  render() {
    const { show, onClose } = this.props;
    
    return (
      <Modal
        bsSize='lg'
        className='VullcanModal'
        show={show}
        onHide={onClose}
      >
        <Modal.Header
          closeButton
        >
          <Modal.Title>Vullcan Design</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col xs={12}
                className="text-center"
              >
                <h4>Desenvolvido para:</h4>
                <br/>
                <img
                  src="images/logo.png"
                  className="img-responsive center-block"
                  alt="Milena Decker Arquitetura"
                />
                <br/>
                <h4>Por:</h4>
                <br/>
                <a
                  href="https://www.instagram.com/vullcandesign/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/logo-vullcan.png"
                    className="img-responsive center-block"
                    alt="Vullcan Design"
                  />
                </a>
                <br/>
                <p>Designed by <strong>Andrea Decker</strong></p>
                <p>Developed by <strong>Valmor Junior</strong></p>
                <p>
                  Powered by&nbsp;
                  <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
                      alt="ReactJS"
                      height="15"
                    />
                    <strong>React</strong>
                  </a>
                </p>
                <p>&copy; &ndash; 2017</p>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    );
  }
}

VullcanModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.any,
};

export default VullcanModal;