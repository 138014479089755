import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Button } from 'react-bootstrap';

class FormButton extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      error: null,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(evt) {
    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      onClick(evt);
    }
  }

  render() {
    const { controlId, buttonText } = this.props;
    return (
      <FormGroup>
        <Button
          variant="primary"
          id={controlId}
          onClick={this.handleClick}
        >
          {buttonText}
        </Button>
      </FormGroup>
    );
  }

}

FormButton.propTypes = {
  controlId: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormButton;