import { Carousel, Grid, Row } from "react-bootstrap";
import Clip from "../components/Common/Clip";
import { ClipList } from "../AppData";
import PageWrapper from "../components/Common/PageWrapper";
import React from "react";
import viewport from "../components/HOC/viewport";

class Clipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 0,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(currentPageIndex, e) {
    this.setState({ currentPageIndex });
  }

  render() {
    const { viewport = "lg" } = this.props;
    const { currentPageIndex = 0 } = this.state;
    let itemsPerPage = 5;
    switch (viewport) {
      case "xs":
        itemsPerPage = 1;
        break;
      case "sm":
        itemsPerPage = 2;
        break;
      default:
        itemsPerPage = 5;
    }
    const pages = [];
    let page = 0;
    let item = 0;
    ClipList.forEach((clip, idx) => {
      if (!pages[page]) {
        pages[page] = [];
      }
      pages[page].push(clip);
      item++;
      if (item === itemsPerPage) {
        item = 0;
        page++;
      }
    });

    return (
      <PageWrapper pageName="Clipping" header={true} footer={false}>
        <Grid fluid>
          <Row>
            <Carousel
              activeIndex={currentPageIndex}
              onSelect={this.handleSelect}
              indicators
            >
              {pages.map((currentPage, pageIdx) => {
                return (
                  <Carousel.Item key={`page-${pageIdx}`}>
                    <Grid fluid>
                      <Row>
                        {currentPage.map((currentItem, itemIdx) => {
                          return (
                            <Clip
                              key={`Clipping-${itemIdx}`}
                              data={currentItem}
                            />
                          );
                        })}
                      </Row>
                    </Grid>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Row>
        </Grid>
      </PageWrapper>
    );
  }
}

export default viewport(Clipping);
