import React from 'react';

const viewport = function(WrappedComponent) {
  return class extends React.Component {

    constructor(props) {
      super(props);
      this.state = { isMobile: false, isTablet: false, isDesktop: true };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    
    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    getViewport() {
      const width = window.innerWidth;
      if (width >= 1200) {
        return 'lg';
      } else if (width >= 992) {
        return 'md';
      } else if (width >= 768) {
        return 'sm';
      }
      return 'xs';
    }

    updateWindowDimensions() {
      const viewportSize = this.getViewport();
      this.setState({ viewport: viewportSize });
    }

    render() {
      const { viewport } = this.state;
      return <WrappedComponent viewport={viewport}  />;
    }
  }
};

export default viewport;
