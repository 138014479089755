import React from "react";
import PropTypes from "prop-types";
import { Glyphicon } from "react-bootstrap";

class PhotoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      modalClass: null,
      dimensions: {
        width: 0,
        height: 0,
      },
    };

    this.closeModal = this.closeModal.bind(this);
    this.imageLoad = this.imageLoad.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  modalIsOpen = () => !!this.state.modalshowOpen;

  openModal() {
    this.setState(
      {
        show: true,
      },
      () => {
        document.addEventListener("click", this.closeModal);
      }
    );
  }

  closeModal() {
    this.setState(
      {
        show: false,
      },
      () => {
        const { closeHandler } = this.props;
        document.removeEventListener("click", this.closeModal);
        if (typeof closeHandler === "function") {
          closeHandler();
        }
      }
    );
  }

  handleInnerClick(evt) {
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    return false;
  }

  imageLoad({ target: img }) {
    const modalClass =
      img.naturalHeight > img.naturalWidth ? "portrait" : "landscape";
    this.setState(
      {
        modalClass,
        dimensions: {
          width: img.width,
          height: img.height,
        },
      },
      () => {
        this.photo.className = `photo ${modalClass}`;
      }
    );
  }

  handlePrevClick(evt) {
    const { goToPrev } = this.props;
    if (typeof goToPrev === "function") {
      goToPrev();
    }
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  }

  handleNextClick(evt) {
    const { goToNext } = this.props;
    if (typeof goToNext === "function") {
      goToNext();
    }
    evt.stopPropagation();
    evt.nativeEvent.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  }

  render() {
    const { showPrev, showNext, photo } = this.props;
    const { show } = this.state;

    if (!show || !photo) return null;

    const { fullImage, alt } = photo;

    return (
      <div>
        <div key={`backdrop`} className="modal-backdrop" />
        <div className="photoModal">
          <div className="photoContainer">
            <div
              ref={(ref) => (this.photo = ref)}
              className="photo"
              onClick={this.handleInnerClick}
            >
              <img
                ref={(ref) => (this.image = ref)}
                src={`images/pictures/${fullImage}`}
                className="img-responsive"
                alt={alt}
                onLoad={this.imageLoad}
              />
              <a className="closeButton" onClick={this.closeModal}>
                <Glyphicon glyph="remove" />
              </a>
            </div>
            {showPrev && (
              <div className="prevButton" onClick={this.handlePrevClick}>
                <Glyphicon glyph="menu-left" />
              </div>
            )}
            {showNext && (
              <div className="nextButton" onClick={this.handleNextClick}>
                <Glyphicon glyph="menu-right" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PhotoModal.propTypes = {
  photo: PropTypes.object,
  closeHandler: PropTypes.any,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  goToPrev: PropTypes.func,
  goToNext: PropTypes.func,
};

export default PhotoModal;
