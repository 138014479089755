import Gallery from '../components/Portfolio/Gallery';
import PageWrapper from '../components/Common/PageWrapper';
import React from 'react';
import { PhotoList } from '../AppData';

class Portfolio extends React.Component {
  render() {
    const photos = PhotoList; 
    return (
      <PageWrapper
        pageName="Portfolio"
        header={true}
        footer={false}
      >
        <Gallery
          photoList={photos}
        />
      </PageWrapper>
    );
  }
}

export default Portfolio;
