import PageWrapper from '../components/Common/PageWrapper';
import React from 'react';

class Home extends React.Component {
  render() {
    return (
      <PageWrapper
        pageName="Home"
        header={true}
        footer={false}
      >
        <div id="homeStretchedBackground"/>
      </PageWrapper>
    );
  }
}

export default Home;
