import ContactForm from '../components/Contato/ContactForm';
import PageWrapper from '../components/Common/PageWrapper';
import React from 'react';


class Contato extends React.Component {
  render() {
    return (
      <PageWrapper
        pageName="Contato"
        header={true}
        footer={true}
      >
        <ContactForm />
      </PageWrapper>
    );
  }
}

export default Contato;
