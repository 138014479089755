import Clipping from './pages/Clipping';
import Contato from './pages/Contato';
import Home from './pages/Home';
import Perfil from './pages/Perfil';
import Portfolio from './pages/Portfolio';
import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './styles/Index.scss';

class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route 
            exact
            path="/"
            component={Home}
          />
          <Route 
            path="/perfil"
            component={Perfil}
          />
          <Route 
            path="/portfolio"
            component={Portfolio}
          />
          <Route 
            path="/clipping"
            component={Clipping}
          />
          <Route 
            path="/contato"
            component={Contato}
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
