import PhotoModal from "./PhotoModal";
import Page from "./Page";
import React from "react";
import PropTypes from "prop-types";
import { Carousel, Grid, Row } from "react-bootstrap";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.reqAnimFrame = null;
    this.state = {
      shouldShowPrevArrow: false,
      shouldShowNextArrow: false,
      currentPageIndex: 0,
      currentPhotoIndex: 0,
      currentPhoto: null,
    };
    this.pageRefs = [];
    this.pages = [];
    this.handleSelect = this.handleSelect.bind(this);
    this.buildPageList = this.buildPageList.bind(this);
    this.showPhotoModal = this.showPhotoModal.bind(this);
    this.hidePhotoModal = this.hidePhotoModal.bind(this);
    this.goToPrev = this.goToPrev.bind(this);
    this.goToNext = this.goToNext.bind(this);
  }

  handleSelect(currentPageIndex) {
    this.setState({ currentPageIndex });
  }

  buildPageList() {
    const { photoList } = this.props;
    const limit = 45;
    let count = 0;
    this.pages = [[]];
    if (!Array.isArray(photoList) || !photoList.length) return null;
    photoList.forEach((photo) => {
      if (count === limit) {
        count = 0;
        this.pages.push([]);
      }
      this.pages[this.pages.length - 1].push(photo);
      count++;
    });
  }

  showPhotoModal(pageIndex, photoIndex) {
    if (pageIndex !== null && photoIndex !== null) {
      this.setState(
        {
          shouldShowPrevArrow: !!(pageIndex > 0 || photoIndex > 0),
          shouldShowNextArrow: !!(
            pageIndex < this.pages.length - 1 ||
            photoIndex < this.pages[pageIndex].length - 1
          ),
          currentPageIndex: pageIndex,
          currentPhotoIndex: photoIndex,
          currentPhoto: this.pages[pageIndex][photoIndex],
        },
        this.PhotoModal.openModal()
      );
    }
  }

  hidePhotoModal() {
    this.setState({
      shouldShowPrevArrow: false,
      shouldShowNextArrow: false,
      currentPhotoIndex: 0,
      currentPhoto: null,
    });
  }

  goToPrev() {
    const { currentPageIndex, currentPhotoIndex } = this.state;
    let pageIndex = currentPageIndex,
      photoIndex = currentPhotoIndex;
    if (photoIndex > 0) {
      photoIndex--;
    } else if (pageIndex > 0) {
      pageIndex--;
      photoIndex = this.pages[pageIndex].length - 1;
    }
    this.showPhotoModal(pageIndex, photoIndex);
  }

  goToNext() {
    const { currentPageIndex, currentPhotoIndex } = this.state;
    let pageIndex = currentPageIndex,
      photoIndex = currentPhotoIndex;
    if (photoIndex + 1 < this.pages[pageIndex].length) {
      photoIndex++;
    } else if (pageIndex < this.pages.length - 1) {
      pageIndex++;
      photoIndex = 0;
    }
    this.showPhotoModal(pageIndex, photoIndex);
  }

  render() {
    const {
      shouldShowPrevArrow,
      shouldShowNextArrow,
      currentPageIndex,
      currentPhoto,
    } = this.state;
    this.buildPageList();
    return (
      <Grid fluid>
        <Row className="galleryRow">
          <Carousel
            activeIndex={currentPageIndex}
            onSelect={this.handleSelect}
            interval={null}
            controls={false}
            indicators
          >
            {this.pages.map((page, pageIndex) => (
              <Carousel.Item
                key={`item${pageIndex}`}
                data-page-index={pageIndex}
              >
                <Page
                  ref={(ref) => this.pageRefs.push(ref)}
                  key={`page${pageIndex}`}
                  pageIndex={pageIndex}
                  photos={page}
                  showModal={this.showPhotoModal}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <PhotoModal
            ref={(ref) => (this.PhotoModal = ref)}
            photo={currentPhoto}
            closeHandler={this.hidePhotoModal}
            showPrev={shouldShowPrevArrow}
            showNext={shouldShowNextArrow}
            goToPrev={this.goToPrev}
            goToNext={this.goToNext}
          />
        </Row>
      </Grid>
    );
  }
}

Gallery.propTypes = {
  photoList: PropTypes.arrayOf(PropTypes.object),
};

export default Gallery;
