import React from 'react';

export const PhotoList = [
  { thumbnail: 'page1/img1.jpg', fullImage: 'page1/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page1/img2.jpg', fullImage: 'page1/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page1/img3.jpg', fullImage: 'page1/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page1/img4.jpg', fullImage: 'page1/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page1/img5.jpg', fullImage: 'page1/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page1/img6.jpg', fullImage: 'page1/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page1/img7.jpg', fullImage: 'page1/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page1/img8.jpg', fullImage: 'page1/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page1/img9.jpg', fullImage: 'page1/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
  { thumbnail: 'page1/img10.jpg', fullImage: 'page1/img10-large.jpg', width: 1024, height: 768, alt: 'Image 10' },
  { thumbnail: 'page1/img11.jpg', fullImage: 'page1/img11-large.jpg', width: 1024, height: 768, alt: 'Image 11' },
  { thumbnail: 'page1/img12.jpg', fullImage: 'page1/img12-large.jpg', width: 1024, height: 768, alt: 'Image 12' },
  { thumbnail: 'page1/img13.jpg', fullImage: 'page1/img13-large.jpg', width: 1024, height: 768, alt: 'Image 13' },
  { thumbnail: 'page1/img14.jpg', fullImage: 'page1/img14-large.jpg', width: 1024, height: 768, alt: 'Image 14' },
  { thumbnail: 'page1/img15.jpg', fullImage: 'page1/img15-large.jpg', width: 1024, height: 768, alt: 'Image 15' },
  { thumbnail: 'page1/img16.jpg', fullImage: 'page1/img16-large.jpg', width: 1024, height: 768, alt: 'Image 16' },
  { thumbnail: 'page1/img17.jpg', fullImage: 'page1/img17-large.jpg', width: 1024, height: 768, alt: 'Image 17' },
  { thumbnail: 'page1/img18.jpg', fullImage: 'page1/img18-large.jpg', width: 1024, height: 768, alt: 'Image 18' },
  { thumbnail: 'page1/img19.jpg', fullImage: 'page1/img19-large.jpg', width: 1024, height: 768, alt: 'Image 19' },
  { thumbnail: 'page1/img20.jpg', fullImage: 'page1/img20-large.jpg', width: 1024, height: 768, alt: 'Image 20' },
  { thumbnail: 'page1/img21.jpg', fullImage: 'page1/img21-large.jpg', width: 1024, height: 768, alt: 'Image 21' },
  { thumbnail: 'page1/img22.jpg', fullImage: 'page1/img22-large.jpg', width: 1024, height: 768, alt: 'Image 22' },
  { thumbnail: 'page1/img23.jpg', fullImage: 'page1/img23-large.jpg', width: 1024, height: 768, alt: 'Image 23' },
  { thumbnail: 'page1/img24.jpg', fullImage: 'page1/img24-large.jpg', width: 1024, height: 768, alt: 'Image 24' },
  { thumbnail: 'page1/img25.jpg', fullImage: 'page1/img25-large.jpg', width: 1024, height: 768, alt: 'Image 25' },
  { thumbnail: 'page1/img26.jpg', fullImage: 'page1/img26-large.jpg', width: 1024, height: 768, alt: 'Image 26' },
  { thumbnail: 'page1/img27.jpg', fullImage: 'page1/img27-large.jpg', width: 1024, height: 768, alt: 'Image 27' },
  { thumbnail: 'page1/img28.jpg', fullImage: 'page1/img28-large.jpg', width: 1024, height: 768, alt: 'Image 28' },
  { thumbnail: 'page1/img29.jpg', fullImage: 'page1/img29-large.jpg', width: 1024, height: 768, alt: 'Image 29' },
  { thumbnail: 'page1/img30.jpg', fullImage: 'page1/img30-large.jpg', width: 1024, height: 768, alt: 'Image 30' },
  { thumbnail: 'page1/img31.jpg', fullImage: 'page1/img31-large.jpg', width: 1024, height: 768, alt: 'Image 31' },
  { thumbnail: 'page1/img32.jpg', fullImage: 'page1/img32-large.jpg', width: 1024, height: 768, alt: 'Image 32' },
  { thumbnail: 'page1/img33.jpg', fullImage: 'page1/img33-large.jpg', width: 1024, height: 768, alt: 'Image 33' },
  { thumbnail: 'page1/img34.jpg', fullImage: 'page1/img34-large.jpg', width: 1024, height: 768, alt: 'Image 34' },
  { thumbnail: 'page1/img35.jpg', fullImage: 'page1/img35-large.jpg', width: 1024, height: 768, alt: 'Image 35' },
  { thumbnail: 'page1/img36.jpg', fullImage: 'page1/img36-large.jpg', width: 1024, height: 768, alt: 'Image 36' },
  { thumbnail: 'page1/img37.jpg', fullImage: 'page1/img37-large.jpg', width: 1024, height: 768, alt: 'Image 37' },
  { thumbnail: 'page1/img38.jpg', fullImage: 'page1/img38-large.jpg', width: 1024, height: 768, alt: 'Image 38' },
  { thumbnail: 'page1/img39.jpg', fullImage: 'page1/img39-large.jpg', width: 1024, height: 768, alt: 'Image 39' },
  { thumbnail: 'page1/img40.jpg', fullImage: 'page1/img40-large.jpg', width: 1024, height: 768, alt: 'Image 40' },
  { thumbnail: 'page1/img41.jpg', fullImage: 'page1/img41-large.jpg', width: 1024, height: 768, alt: 'Image 41' },
  { thumbnail: 'page1/img42.jpg', fullImage: 'page1/img42-large.jpg', width: 1024, height: 768, alt: 'Image 42' },
  { thumbnail: 'page1/img43.jpg', fullImage: 'page1/img43-large.jpg', width: 1024, height: 768, alt: 'Image 43' },
  { thumbnail: 'page1/img44.jpg', fullImage: 'page1/img44-large.jpg', width: 1024, height: 768, alt: 'Image 44' },
  { thumbnail: 'page1/img45.jpg', fullImage: 'page1/img45-large.jpg', width: 1024, height: 768, alt: 'Image 45' },
  { thumbnail: 'page2/img1.jpg', fullImage: 'page2/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page2/img2.jpg', fullImage: 'page2/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page2/img3.jpg', fullImage: 'page2/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page2/img4.jpg', fullImage: 'page2/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page2/img5.jpg', fullImage: 'page2/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page2/img6.jpg', fullImage: 'page2/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page2/img7.jpg', fullImage: 'page2/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page2/img8.jpg', fullImage: 'page2/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page2/img9.jpg', fullImage: 'page2/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
  { thumbnail: 'page2/img10.jpg', fullImage: 'page2/img10-large.jpg', width: 1024, height: 768, alt: 'Image 10' },
  { thumbnail: 'page2/img11.jpg', fullImage: 'page2/img11-large.jpg', width: 1024, height: 768, alt: 'Image 11' },
  { thumbnail: 'page2/img12.jpg', fullImage: 'page2/img12-large.jpg', width: 1024, height: 768, alt: 'Image 12' },
  { thumbnail: 'page2/img13.jpg', fullImage: 'page2/img13-large.jpg', width: 1024, height: 768, alt: 'Image 13' },
  { thumbnail: 'page2/img14.jpg', fullImage: 'page2/img14-large.jpg', width: 1024, height: 768, alt: 'Image 14' },
  { thumbnail: 'page2/img15.jpg', fullImage: 'page2/img15-large.jpg', width: 1024, height: 768, alt: 'Image 15' },
  { thumbnail: 'page2/img16.jpg', fullImage: 'page2/img16-large.jpg', width: 1024, height: 768, alt: 'Image 16' },
  { thumbnail: 'page2/img17.jpg', fullImage: 'page2/img17-large.jpg', width: 1024, height: 768, alt: 'Image 17' },
  { thumbnail: 'page2/img18.jpg', fullImage: 'page2/img18-large.jpg', width: 1024, height: 768, alt: 'Image 18' },
  { thumbnail: 'page2/img19.jpg', fullImage: 'page2/img19-large.jpg', width: 1024, height: 768, alt: 'Image 19' },
  { thumbnail: 'page2/img20.jpg', fullImage: 'page2/img20-large.jpg', width: 1024, height: 768, alt: 'Image 20' },
  { thumbnail: 'page2/img21.jpg', fullImage: 'page2/img21-large.jpg', width: 1024, height: 768, alt: 'Image 21' },
  { thumbnail: 'page2/img22.jpg', fullImage: 'page2/img22-large.jpg', width: 1024, height: 768, alt: 'Image 22' },
  { thumbnail: 'page2/img23.jpg', fullImage: 'page2/img23-large.jpg', width: 1024, height: 768, alt: 'Image 23' },
  { thumbnail: 'page2/img24.jpg', fullImage: 'page2/img24-large.jpg', width: 1024, height: 768, alt: 'Image 24' },
  { thumbnail: 'page2/img25.jpg', fullImage: 'page2/img25-large.jpg', width: 1024, height: 768, alt: 'Image 25' },
  { thumbnail: 'page2/img26.jpg', fullImage: 'page2/img26-large.jpg', width: 1024, height: 768, alt: 'Image 26' },
  { thumbnail: 'page2/img27.jpg', fullImage: 'page2/img27-large.jpg', width: 1024, height: 768, alt: 'Image 27' },
  { thumbnail: 'page2/img28.jpg', fullImage: 'page2/img28-large.jpg', width: 1024, height: 768, alt: 'Image 28' },
  { thumbnail: 'page2/img29.jpg', fullImage: 'page2/img29-large.jpg', width: 1024, height: 768, alt: 'Image 29' },
  { thumbnail: 'page2/img30.jpg', fullImage: 'page2/img30-large.jpg', width: 1024, height: 768, alt: 'Image 30' },
  { thumbnail: 'page2/img31.jpg', fullImage: 'page2/img31-large.jpg', width: 1024, height: 768, alt: 'Image 31' },
  { thumbnail: 'page2/img32.jpg', fullImage: 'page2/img32-large.jpg', width: 1024, height: 768, alt: 'Image 32' },
  { thumbnail: 'page2/img33.jpg', fullImage: 'page2/img33-large.jpg', width: 1024, height: 768, alt: 'Image 33' },
  { thumbnail: 'page2/img34.jpg', fullImage: 'page2/img34-large.jpg', width: 1024, height: 768, alt: 'Image 34' },
  { thumbnail: 'page2/img35.jpg', fullImage: 'page2/img35-large.jpg', width: 1024, height: 768, alt: 'Image 35' },
  { thumbnail: 'page2/img36.jpg', fullImage: 'page2/img36-large.jpg', width: 1024, height: 768, alt: 'Image 36' },
  { thumbnail: 'page2/img37.jpg', fullImage: 'page2/img37-large.jpg', width: 1024, height: 768, alt: 'Image 37' },
  { thumbnail: 'page2/img38.jpg', fullImage: 'page2/img38-large.jpg', width: 1024, height: 768, alt: 'Image 38' },
  { thumbnail: 'page2/img39.jpg', fullImage: 'page2/img39-large.jpg', width: 1024, height: 768, alt: 'Image 39' },
  { thumbnail: 'page2/img40.jpg', fullImage: 'page2/img40-large.jpg', width: 1024, height: 768, alt: 'Image 40' },
  { thumbnail: 'page2/img41.jpg', fullImage: 'page2/img41-large.jpg', width: 1024, height: 768, alt: 'Image 41' },
  { thumbnail: 'page2/img42.jpg', fullImage: 'page2/img42-large.jpg', width: 1024, height: 768, alt: 'Image 42' },
  { thumbnail: 'page2/img43.jpg', fullImage: 'page2/img43-large.jpg', width: 1024, height: 768, alt: 'Image 43' },
  { thumbnail: 'page2/img44.jpg', fullImage: 'page2/img44-large.jpg', width: 1024, height: 768, alt: 'Image 44' },
  { thumbnail: 'page2/img45.jpg', fullImage: 'page2/img45-large.jpg', width: 1024, height: 768, alt: 'Image 45' },
  { thumbnail: 'page3/img1.jpg', fullImage: 'page3/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page3/img2.jpg', fullImage: 'page3/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page3/img3.jpg', fullImage: 'page3/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page3/img4.jpg', fullImage: 'page3/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page3/img5.jpg', fullImage: 'page3/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page3/img6.jpg', fullImage: 'page3/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page3/img7.jpg', fullImage: 'page3/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page3/img8.jpg', fullImage: 'page3/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page3/img9.jpg', fullImage: 'page3/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
  { thumbnail: 'page3/img10.jpg', fullImage: 'page3/img10-large.jpg', width: 1024, height: 768, alt: 'Image 10' },
  { thumbnail: 'page3/img11.jpg', fullImage: 'page3/img11-large.jpg', width: 1024, height: 768, alt: 'Image 11' },
  { thumbnail: 'page3/img12.jpg', fullImage: 'page3/img12-large.jpg', width: 1024, height: 768, alt: 'Image 12' },
  { thumbnail: 'page3/img13.jpg', fullImage: 'page3/img13-large.jpg', width: 1024, height: 768, alt: 'Image 13' },
  { thumbnail: 'page3/img14.jpg', fullImage: 'page3/img14-large.jpg', width: 1024, height: 768, alt: 'Image 14' },
  { thumbnail: 'page3/img15.jpg', fullImage: 'page3/img15-large.jpg', width: 1024, height: 768, alt: 'Image 15' },
  { thumbnail: 'page3/img16.jpg', fullImage: 'page3/img16-large.jpg', width: 1024, height: 768, alt: 'Image 16' },
  { thumbnail: 'page3/img17.jpg', fullImage: 'page3/img17-large.jpg', width: 1024, height: 768, alt: 'Image 17' },
  { thumbnail: 'page3/img18.jpg', fullImage: 'page3/img18-large.jpg', width: 1024, height: 768, alt: 'Image 18' },
  { thumbnail: 'page3/img19.jpg', fullImage: 'page3/img19-large.jpg', width: 1024, height: 768, alt: 'Image 19' },
  { thumbnail: 'page3/img20.jpg', fullImage: 'page3/img20-large.jpg', width: 1024, height: 768, alt: 'Image 20' },
  { thumbnail: 'page3/img21.jpg', fullImage: 'page3/img21-large.jpg', width: 1024, height: 768, alt: 'Image 21' },
  { thumbnail: 'page3/img22.jpg', fullImage: 'page3/img22-large.jpg', width: 1024, height: 768, alt: 'Image 22' },
  { thumbnail: 'page3/img23.jpg', fullImage: 'page3/img23-large.jpg', width: 1024, height: 768, alt: 'Image 23' },
  { thumbnail: 'page3/img24.jpg', fullImage: 'page3/img24-large.jpg', width: 1024, height: 768, alt: 'Image 24' },
  { thumbnail: 'page3/img25.jpg', fullImage: 'page3/img25-large.jpg', width: 1024, height: 768, alt: 'Image 25' },
  { thumbnail: 'page3/img26.jpg', fullImage: 'page3/img26-large.jpg', width: 1024, height: 768, alt: 'Image 26' },
  { thumbnail: 'page3/img27.jpg', fullImage: 'page3/img27-large.jpg', width: 1024, height: 768, alt: 'Image 27' },
  { thumbnail: 'page3/img28.jpg', fullImage: 'page3/img28-large.jpg', width: 1024, height: 768, alt: 'Image 28' },
  { thumbnail: 'page3/img29.jpg', fullImage: 'page3/img29-large.jpg', width: 1024, height: 768, alt: 'Image 29' },
  { thumbnail: 'page3/img30.jpg', fullImage: 'page3/img30-large.jpg', width: 1024, height: 768, alt: 'Image 30' },
  { thumbnail: 'page3/img31.jpg', fullImage: 'page3/img31-large.jpg', width: 1024, height: 768, alt: 'Image 31' },
  { thumbnail: 'page3/img32.jpg', fullImage: 'page3/img32-large.jpg', width: 1024, height: 768, alt: 'Image 32' },
  { thumbnail: 'page3/img33.jpg', fullImage: 'page3/img33-large.jpg', width: 1024, height: 768, alt: 'Image 33' },
  { thumbnail: 'page3/img34.jpg', fullImage: 'page3/img34-large.jpg', width: 1024, height: 768, alt: 'Image 34' },
  { thumbnail: 'page3/img35.jpg', fullImage: 'page3/img35-large.jpg', width: 1024, height: 768, alt: 'Image 35' },
  { thumbnail: 'page3/img36.jpg', fullImage: 'page3/img36-large.jpg', width: 1024, height: 768, alt: 'Image 36' },
  { thumbnail: 'page3/img37.jpg', fullImage: 'page3/img37-large.jpg', width: 1024, height: 768, alt: 'Image 37' },
  { thumbnail: 'page3/img38.jpg', fullImage: 'page3/img38-large.jpg', width: 1024, height: 768, alt: 'Image 38' },
  { thumbnail: 'page3/img39.jpg', fullImage: 'page3/img39-large.jpg', width: 1024, height: 768, alt: 'Image 39' },
  { thumbnail: 'page3/img40.jpg', fullImage: 'page3/img40-large.jpg', width: 1024, height: 768, alt: 'Image 40' },
  { thumbnail: 'page3/img41.jpg', fullImage: 'page3/img41-large.jpg', width: 1024, height: 768, alt: 'Image 41' },
  { thumbnail: 'page3/img42.jpg', fullImage: 'page3/img42-large.jpg', width: 1024, height: 768, alt: 'Image 42' },
  { thumbnail: 'page3/img43.jpg', fullImage: 'page3/img43-large.jpg', width: 1024, height: 768, alt: 'Image 43' },
  { thumbnail: 'page3/img44.jpg', fullImage: 'page3/img44-large.jpg', width: 1024, height: 768, alt: 'Image 44' },
  { thumbnail: 'page3/img45.jpg', fullImage: 'page3/img45-large.jpg', width: 1024, height: 768, alt: 'Image 45' },
  { thumbnail: 'page4/img1.jpg', fullImage: 'page4/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page4/img2.jpg', fullImage: 'page4/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page4/img3.jpg', fullImage: 'page4/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page4/img4.jpg', fullImage: 'page4/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page4/img5.jpg', fullImage: 'page4/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page4/img6.jpg', fullImage: 'page4/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page4/img7.jpg', fullImage: 'page4/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page4/img8.jpg', fullImage: 'page4/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page4/img9.jpg', fullImage: 'page4/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
  { thumbnail: 'page4/img10.jpg', fullImage: 'page4/img10-large.jpg', width: 1024, height: 768, alt: 'Image 10' },
  { thumbnail: 'page4/img11.jpg', fullImage: 'page4/img11-large.jpg', width: 1024, height: 768, alt: 'Image 11' },
  { thumbnail: 'page4/img12.jpg', fullImage: 'page4/img12-large.jpg', width: 1024, height: 768, alt: 'Image 12' },
  { thumbnail: 'page4/img13.jpg', fullImage: 'page4/img13-large.jpg', width: 1024, height: 768, alt: 'Image 13' },
  { thumbnail: 'page4/img14.jpg', fullImage: 'page4/img14-large.jpg', width: 1024, height: 768, alt: 'Image 14' },
  { thumbnail: 'page4/img15.jpg', fullImage: 'page4/img15-large.jpg', width: 1024, height: 768, alt: 'Image 15' },
  { thumbnail: 'page4/img16.jpg', fullImage: 'page4/img16-large.jpg', width: 1024, height: 768, alt: 'Image 16' },
  { thumbnail: 'page4/img17.jpg', fullImage: 'page4/img17-large.jpg', width: 1024, height: 768, alt: 'Image 17' },
  { thumbnail: 'page4/img18.jpg', fullImage: 'page4/img18-large.jpg', width: 1024, height: 768, alt: 'Image 18' },
  { thumbnail: 'page4/img19.jpg', fullImage: 'page4/img19-large.jpg', width: 1024, height: 768, alt: 'Image 19' },
  { thumbnail: 'page4/img20.jpg', fullImage: 'page4/img20-large.jpg', width: 1024, height: 768, alt: 'Image 20' },
  { thumbnail: 'page4/img21.jpg', fullImage: 'page4/img21-large.jpg', width: 1024, height: 768, alt: 'Image 21' },
  { thumbnail: 'page4/img22.jpg', fullImage: 'page4/img22-large.jpg', width: 1024, height: 768, alt: 'Image 22' },
  { thumbnail: 'page4/img23.jpg', fullImage: 'page4/img23-large.jpg', width: 1024, height: 768, alt: 'Image 23' },
  { thumbnail: 'page4/img24.jpg', fullImage: 'page4/img24-large.jpg', width: 1024, height: 768, alt: 'Image 24' },
  { thumbnail: 'page4/img25.jpg', fullImage: 'page4/img25-large.jpg', width: 1024, height: 768, alt: 'Image 25' },
  { thumbnail: 'page4/img26.jpg', fullImage: 'page4/img26-large.jpg', width: 1024, height: 768, alt: 'Image 26' },
  { thumbnail: 'page4/img27.jpg', fullImage: 'page4/img27-large.jpg', width: 1024, height: 768, alt: 'Image 27' },
  { thumbnail: 'page4/img28.jpg', fullImage: 'page4/img28-large.jpg', width: 1024, height: 768, alt: 'Image 28' },
  { thumbnail: 'page4/img29.jpg', fullImage: 'page4/img29-large.jpg', width: 1024, height: 768, alt: 'Image 29' },
  { thumbnail: 'page4/img30.jpg', fullImage: 'page4/img30-large.jpg', width: 1024, height: 768, alt: 'Image 30' },
  { thumbnail: 'page4/img31.jpg', fullImage: 'page4/img31-large.jpg', width: 1024, height: 768, alt: 'Image 31' },
  { thumbnail: 'page4/img32.jpg', fullImage: 'page4/img32-large.jpg', width: 1024, height: 768, alt: 'Image 32' },
  { thumbnail: 'page4/img33.jpg', fullImage: 'page4/img33-large.jpg', width: 1024, height: 768, alt: 'Image 33' },
  { thumbnail: 'page4/img34.jpg', fullImage: 'page4/img34-large.jpg', width: 1024, height: 768, alt: 'Image 34' },
  { thumbnail: 'page4/img35.jpg', fullImage: 'page4/img35-large.jpg', width: 1024, height: 768, alt: 'Image 35' },
  { thumbnail: 'page4/img36.jpg', fullImage: 'page4/img36-large.jpg', width: 1024, height: 768, alt: 'Image 36' },
  { thumbnail: 'page4/img37.jpg', fullImage: 'page4/img37-large.jpg', width: 1024, height: 768, alt: 'Image 37' },
  { thumbnail: 'page4/img38.jpg', fullImage: 'page4/img38-large.jpg', width: 1024, height: 768, alt: 'Image 38' },
  { thumbnail: 'page4/img39.jpg', fullImage: 'page4/img39-large.jpg', width: 1024, height: 768, alt: 'Image 39' },
  { thumbnail: 'page4/img40.jpg', fullImage: 'page4/img40-large.jpg', width: 1024, height: 768, alt: 'Image 40' },
  { thumbnail: 'page4/img41.jpg', fullImage: 'page4/img41-large.jpg', width: 1024, height: 768, alt: 'Image 41' },
  { thumbnail: 'page4/img42.jpg', fullImage: 'page4/img42-large.jpg', width: 1024, height: 768, alt: 'Image 42' },
  { thumbnail: 'page4/img43.jpg', fullImage: 'page4/img43-large.jpg', width: 1024, height: 768, alt: 'Image 43' },
  { thumbnail: 'page4/img44.jpg', fullImage: 'page4/img44-large.jpg', width: 1024, height: 768, alt: 'Image 44' },
  { thumbnail: 'page4/img45.jpg', fullImage: 'page4/img45-large.jpg', width: 1024, height: 768, alt: 'Image 45' },
  { thumbnail: 'page5/img1.jpg', fullImage: 'page5/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page5/img2.jpg', fullImage: 'page5/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page5/img3.jpg', fullImage: 'page5/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page5/img4.jpg', fullImage: 'page5/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page5/img5.jpg', fullImage: 'page5/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page5/img6.jpg', fullImage: 'page5/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page5/img7.jpg', fullImage: 'page5/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page5/img8.jpg', fullImage: 'page5/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page5/img9.jpg', fullImage: 'page5/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
  { thumbnail: 'page5/img10.jpg', fullImage: 'page5/img10-large.jpg', width: 1024, height: 768, alt: 'Image 10' },
  { thumbnail: 'page5/img11.jpg', fullImage: 'page5/img11-large.jpg', width: 1024, height: 768, alt: 'Image 11' },
  { thumbnail: 'page5/img12.jpg', fullImage: 'page5/img12-large.jpg', width: 1024, height: 768, alt: 'Image 12' },
  { thumbnail: 'page5/img13.jpg', fullImage: 'page5/img13-large.jpg', width: 1024, height: 768, alt: 'Image 13' },
  { thumbnail: 'page5/img14.jpg', fullImage: 'page5/img14-large.jpg', width: 1024, height: 768, alt: 'Image 14' },
  { thumbnail: 'page5/img15.jpg', fullImage: 'page5/img15-large.jpg', width: 1024, height: 768, alt: 'Image 15' },
  { thumbnail: 'page5/img16.jpg', fullImage: 'page5/img16-large.jpg', width: 1024, height: 768, alt: 'Image 16' },
  { thumbnail: 'page5/img17.jpg', fullImage: 'page5/img17-large.jpg', width: 1024, height: 768, alt: 'Image 17' },
  { thumbnail: 'page5/img18.jpg', fullImage: 'page5/img18-large.jpg', width: 1024, height: 768, alt: 'Image 18' },
  { thumbnail: 'page5/img19.jpg', fullImage: 'page5/img19-large.jpg', width: 1024, height: 768, alt: 'Image 19' },
  { thumbnail: 'page5/img20.jpg', fullImage: 'page5/img20-large.jpg', width: 1024, height: 768, alt: 'Image 20' },
  { thumbnail: 'page5/img21.jpg', fullImage: 'page5/img21-large.jpg', width: 1024, height: 768, alt: 'Image 21' },
  { thumbnail: 'page5/img22.jpg', fullImage: 'page5/img22-large.jpg', width: 1024, height: 768, alt: 'Image 22' },
  { thumbnail: 'page5/img23.jpg', fullImage: 'page5/img23-large.jpg', width: 1024, height: 768, alt: 'Image 23' },
  { thumbnail: 'page5/img24.jpg', fullImage: 'page5/img24-large.jpg', width: 1024, height: 768, alt: 'Image 24' },
  { thumbnail: 'page5/img25.jpg', fullImage: 'page5/img25-large.jpg', width: 1024, height: 768, alt: 'Image 25' },
  { thumbnail: 'page5/img26.jpg', fullImage: 'page5/img26-large.jpg', width: 1024, height: 768, alt: 'Image 26' },
  { thumbnail: 'page5/img27.jpg', fullImage: 'page5/img27-large.jpg', width: 1024, height: 768, alt: 'Image 27' },
  { thumbnail: 'page5/img28.jpg', fullImage: 'page5/img28-large.jpg', width: 1024, height: 768, alt: 'Image 28' },
  { thumbnail: 'page5/img29.jpg', fullImage: 'page5/img29-large.jpg', width: 1024, height: 768, alt: 'Image 29' },
  { thumbnail: 'page5/img30.jpg', fullImage: 'page5/img30-large.jpg', width: 1024, height: 768, alt: 'Image 30' },
  { thumbnail: 'page5/img31.jpg', fullImage: 'page5/img31-large.jpg', width: 1024, height: 768, alt: 'Image 31' },
  { thumbnail: 'page5/img32.jpg', fullImage: 'page5/img32-large.jpg', width: 1024, height: 768, alt: 'Image 32' },
  { thumbnail: 'page5/img33.jpg', fullImage: 'page5/img33-large.jpg', width: 1024, height: 768, alt: 'Image 33' },
  { thumbnail: 'page5/img34.jpg', fullImage: 'page5/img34-large.jpg', width: 1024, height: 768, alt: 'Image 34' },
  { thumbnail: 'page5/img35.jpg', fullImage: 'page5/img35-large.jpg', width: 1024, height: 768, alt: 'Image 35' },
  { thumbnail: 'page5/img36.jpg', fullImage: 'page5/img36-large.jpg', width: 1024, height: 768, alt: 'Image 36' },
  { thumbnail: 'page5/img37.jpg', fullImage: 'page5/img37-large.jpg', width: 1024, height: 768, alt: 'Image 37' },
  { thumbnail: 'page5/img38.jpg', fullImage: 'page5/img38-large.jpg', width: 1024, height: 768, alt: 'Image 38' },
  { thumbnail: 'page5/img39.jpg', fullImage: 'page5/img39-large.jpg', width: 1024, height: 768, alt: 'Image 39' },
  { thumbnail: 'page5/img40.jpg', fullImage: 'page5/img40-large.jpg', width: 1024, height: 768, alt: 'Image 40' },
  { thumbnail: 'page5/img41.jpg', fullImage: 'page5/img41-large.jpg', width: 1024, height: 768, alt: 'Image 41' },
  { thumbnail: 'page5/img42.jpg', fullImage: 'page5/img42-large.jpg', width: 1024, height: 768, alt: 'Image 42' },
  { thumbnail: 'page5/img43.jpg', fullImage: 'page5/img43-large.jpg', width: 1024, height: 768, alt: 'Image 43' },
  { thumbnail: 'page5/img44.jpg', fullImage: 'page5/img44-large.jpg', width: 1024, height: 768, alt: 'Image 44' },
  { thumbnail: 'page5/img45.jpg', fullImage: 'page5/img45-large.jpg', width: 1024, height: 768, alt: 'Image 45' },
  { thumbnail: 'page6/img1.jpg', fullImage: 'page6/img1-large.jpg', width: 1024, height: 768, alt: 'Image 1' },
  { thumbnail: 'page6/img2.jpg', fullImage: 'page6/img2-large.jpg', width: 1024, height: 768, alt: 'Image 2' },
  { thumbnail: 'page6/img3.jpg', fullImage: 'page6/img3-large.jpg', width: 1024, height: 768, alt: 'Image 3' },
  { thumbnail: 'page6/img4.jpg', fullImage: 'page6/img4-large.jpg', width: 1024, height: 768, alt: 'Image 4' },
  { thumbnail: 'page6/img5.jpg', fullImage: 'page6/img5-large.jpg', width: 1024, height: 768, alt: 'Image 5' },
  { thumbnail: 'page6/img6.jpg', fullImage: 'page6/img6-large.jpg', width: 1024, height: 768, alt: 'Image 6' },
  { thumbnail: 'page6/img7.jpg', fullImage: 'page6/img7-large.jpg', width: 1024, height: 768, alt: 'Image 7' },
  { thumbnail: 'page6/img8.jpg', fullImage: 'page6/img8-large.jpg', width: 1024, height: 768, alt: 'Image 8' },
  { thumbnail: 'page6/img9.jpg', fullImage: 'page6/img9-large.jpg', width: 1024, height: 768, alt: 'Image 9' },
];

export const ClipList = [
  { img: 'capa-7.jpg', url: 'http://aaidigital.com.br/2019/12/16/projeto-da-cara-nova-aos-banheiros-do-apartamento/', alt: 'Publica&ccedil;&atilde;o AAI Digital &ndash; Banheiros', text: <span>Publica&ccedil;&atilde;o AAI Digital &ndash; Banheiros<br/>&nbsp;</span> },
  { img: 'capa-6.jpg', url: 'http://aaidigital.com.br/2019/10/14/arquiteta-inspira-se-no-estilo-escandinavo-para-criar-uma-casa-leve/', alt: 'Publica&ccedil;&atilde;o AAI Digital &ndash; Casa', text: <span>Publica&ccedil;&atilde;o AAI Digital &ndash; Casa<br/>&nbsp;</span> },
  { img: 'capa-8.jpg', url: 'http://aaidigital.com.br/2019/08/05/projeto-de-consultorio/', alt: 'Publica&ccedil;&atilde;o AAI Digital &ndash; Consult&oacute;rio M&eacute;dico', text: <span>Publica&ccedil;&atilde;o AAI Digital &ndash; Consult&oacute;rio M&eacute;dico<br/>&nbsp;</span> },
  { img: 'capa-9.jpg', url: 'http://aaidigital.com.br/2019/01/31/arquiteta-projeta-dormitorio-ludico-e-funcional/', alt: 'Publica&ccedil;&atilde;o AAI Digital &ndash; Dormit&oacute;rio infantil', text: <span>Publica&ccedil;&atilde;o AAI Digital &ndash; Dormit&oacute;rio infantil<br/>&nbsp;</span> },
  { img: 'capa-10.jpg', url: 'https://issuu.com/revistabendita/docs/revista_28/19', alt: 'Publica&ccedil;&atilde;o Revista Bendita', text: <span>Publica&ccedil;&atilde;o Revista Bendita<br/>&nbsp;</span> },
  { img: 'capa-5.png', url: null, alt: 'Publica&ccedil;&atilde;o na AAI em Revista, Ano XII, n&ordm; 66 &ndash; Jan/Mar &ndash; 2014', text: <span>Publica&ccedil;&atilde;o na AAI em Revista<br/>Ano XII, n&ordm; 66 &ndash; Jan/Mar &ndash; 2014</span> },
  { img: 'capa-4.png', url: null, alt: 'Publica&ccedil;&atilde;o na AAI em Revista, Ano X, n&ordm; 58 &ndash; Jan/Fev &ndash; 2012', text: <span>Publica&ccedil;&atilde;o na AAI em Revista<br/>Ano X, n&ordm; 58 &ndash; Jan/Fev &ndash; 2012</span> },
  { img: 'capa-3.png', url: null, alt: 'Publica&ccedil;&atilde;o na AAI em Revista, Ano IX, n&ordm; 52 &ndash; Jan/Fev &ndash; 2011', text: <span>Publica&ccedil;&atilde;o na AAI em Revista<br/>Ano IX, n&ordm; 52 &ndash; Jan/Fev &ndash; 2011</span> },
  { img: 'capa-2.png', url: null, alt: 'Publica&ccedil;&atilde;o na AAI em Revista, Ano VII, n&ordm; 46 &ndash; Jan/Fev &ndash; 2010', text: <span>Publica&ccedil;&atilde;o na AAI em Revista<br/>Ano VII, n&ordm; 46 &ndash; Jan/Fev &ndash; 2010</span> },
  { img: 'capa-1.png', url: null, alt: 'Publica&ccedil;&atilde;o na AAI em Revista, Ano VII, n&ordm; 40 &ndash; Jan/Fev &ndash; 2009', text: <span>Publica&ccedil;&atilde;o na AAI em Revista<br/>Ano VII, n&ordm; 40 &ndash; Jan/Fev &ndash; 2009</span> },
];