import PhotoThumbnail from './PhotoThumbnail';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row } from 'react-bootstrap';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.showPhotoModal = this.showPhotoModal.bind(this);
  }

  showPhotoModal(photoIndex) {
    const { pageIndex, showModal } = this.props;
    if (typeof showModal === 'function') {
      showModal(pageIndex, photoIndex);
    }
  }

  render() {
    const { photos } = this.props;
    return (
      <Grid fluid>
        <Row>
          {photos.map((photo, photoIndex) => (
            <PhotoThumbnail
              key={`photo${photoIndex}`}
              ref={ref => this[`Photo${photoIndex}`] = ref}
              photoIndex={photoIndex}
              photo={photo}
              clickHandler={this.showPhotoModal}
            />
          ))}
        </Row>
      </Grid>
    );
  }
}

Page.propTypes = {
  pageIndex: PropTypes.number,
  photos: PropTypes.array,
  showModal: PropTypes.func
};

export default Page;